import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import { withRouter } from "next/router";

import React from "react";

import { theme } from "@legup/legup-react-components";

import AuthProvider from "../components/Auth/Auth";

export const App = ({ router }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AuthProvider queryParams={router.query} page="main" />
  </ThemeProvider>
);

App.getInitialProps = async () => ({});

export default withRouter(App);
